<template>
  <b-modal
    id="surgery-files-modal"
    hide-header
    hide-footer
    size="lg"
    centered
    @hidden="closeModal"
  >
    <div class="header">
      <div class="header-content">
        <Close class="ml-auto close-icon" @click="closeModal" />
      </div>
    </div>
    <div class="body">
      <div class="body-content">
        <Tab
          :keys="['Anexar arquivo', 'Adicionar arquivo existente']"
          :index="currentTabIndex"
          @onChange="value => (currentTabIndex = value)"
        >
          <AttachFile
            v-if="currentTabIndex === 0"
            :closeModal="closeModal"
            :clinicId="clinic.id"
            :patientId="patientId"
            :surgeryInformationId="surgeryInformationId"
            :generalSheetId="generalSheetId"
            @change-sent="sent = $event"
          />

          <ExistingFiles
            v-else-if="currentTabIndex === 1"
            :patientId="patientId"
            :surgeryInformationId="surgeryInformationId"
            :generalSheetId="generalSheetId"
            @change-sent="sent = $event"
          />
        </Tab>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  props: {
    patientId: {
      type: String,
      required: true
    },
    surgeryInformationId: String,
    generalSheetId: String
  },
  components: {
    Tab: () => import('@/components/Tab'),
    AttachFile: () => import('./AttachFile'),
    ExistingFiles: () => import('./ExistingFiles'),
    Close: () => import('@/assets/icons/close.svg')
  },
  data: () => ({
    clinic: getCurrentClinic(),
    loading: false,
    sent: false,
    currentTabIndex: 0,
    reloadFiles: false
  }),
  methods: {
    closeModal() {
      if (this.sent) {
        this.$emit('reload-files', true)
      }
      this.clearData()
      this.$bvModal.hide('surgery-files-modal')
    },
    clearData() {
      this.sent = false
      this.currentTabIndex = 0
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
#surgery-files-modal {
  border-radius: 8px;
  .modal-content {
    .modal-body {
      padding: 0;
      .header {
        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 8px 20px 0 0px;
          margin-bottom: -30px;
          z-index: 90;
          position: relative;
          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          }
          .close-icon {
            fill: var(--neutral-500);
            height: 24px;
            width: 24px;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;

        .close-icon {
          fill: var(--neutral-500);
          height: 24px;
          width: 24px;
        }

        .body-content {
          display: flex;
          flex-direction: column;

          .container {
            display: flex;
            flex-direction: column;
            padding: 24px;

            .title-body-content {
              color: var(--dark-blue);
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
            }
          }
        }
      }
    }
  }
}
</style>
